import { Observable, Subject, ReplaySubject, from, of, range } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';

class EventManager {
    constructor()
    {
        this.submitInfo$ = new Subject();
        this.generateQR$ = new Subject();
    }
}

export let eventManager = new EventManager();