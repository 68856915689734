import React, { Component } from "react";
import { Fade, Spinner, Container, Col, Row, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { eventManager } from "../managers/EventManager";
import Logo from '../images/logo.jpg';
var QRCode = require('qrcode.react');

class RegisterForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            processing: this.props.processing,
            qrtext: this.props.qrtext
        };

        // This binding is necessary to make `this` work in the callback
        this.submit = this.submit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    submit() {
        this.setState({ processing: true });
        console.log({nickname: this.nickname, email: this.email});
        eventManager.submitInfo$.next({name: this.nickname, email: this.email})
    }

    showFormular()
    {
        if(this.state.qrtext)
        {
            return "none";
        }
        else
        {
            return (this.state.processing) ? "none" : "block";
        }
    }

    showSpinner()
    {
        if(this.state.qrtext)
        {
            return "none";
        }
        else
        {
            return (this.state.processing) ? "block" : "none";
        }
    }

    showQR()
    {
        if(this.state.qrtext)
        {
            return "block";
        }
        else
        {
            return "none";
        }
    }

    onChange(e){
        switch(e.target.name){
            case "email": this.email = e.target.value;
            break;
            case "nickname": this.nickname = e.target.value;
            break;
        }
    }

    getQRTest()
    {
        if(this.state.qrtext)
        {
            return this.state.qrtext;
        }
        else
        {
            return "?";
        }
    }

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <img src={Logo} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                        <Col /*style={{ backgroundColor: '#3ff', borderColor: '#3ff' }}*/ >&nbsp;</Col>
                        <Col style={{ display: this.showQR()}} xs="12" md="6" className="text-center">
                            <Fade in={true}>
                                <QRCode size={180} value={this.getQRTest()} />
                            </Fade>
                        </Col>
                        <Col style={{ display: this.showFormular() }} xs="12" md="6" className="text-center">
                            <Form>
                                <FormGroup>
                                    <Input type="email" name="email" id="emailInput" placeholder="E-Mail" onChange={this.onChange}/>
                                </FormGroup>
                                <FormGroup>
                                    <Input name="nickname" id="nicknameInput" placeholder="Nickname" onChange={this.onChange}/>
                                </FormGroup>
                                <Button onClick={this.submit}>Submit</Button>
                            </Form>
                        </Col>
                        <Col style={{ display: this.showSpinner() }} xs="12" md="6" className="text-center">
                            <Spinner style={{ width: '9rem', height: '9rem' }} type="grow" />
                        </Col>
                        <Col>&nbsp;</Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default RegisterForm;