import { Observable, Subject, ReplaySubject, from, of, forkJoin, merge, zip, interval } from 'rxjs';
import { map, filter, switchMap, mergeAll, mergeMap } from 'rxjs/operators';

class BackendManager {
    postNewPlayer(playerData)
    {
        return Observable.create(observer => {
            var xmlhttp = new XMLHttpRequest();   // new HttpRequest instance 
            xmlhttp.open("POST", "/pregister");
            xmlhttp.setRequestHeader("Content-Type", "application/json");
            xmlhttp.onerror = (error) =>{
                observer.error(error);
            };
            xmlhttp.onreadystatechange = function() {
                if (xmlhttp.readyState === 4) {
                    observer.next(JSON.parse(xmlhttp.response));
                    observer.complete();
                }
            }
            xmlhttp.onloadend = () =>{
                console.log("load ended");
            };
            xmlhttp.send(JSON.stringify(playerData));
        });
    }
}

export let backendManager = new BackendManager();