import React from "react";
import ReactDOM from "react-dom";
import { AppContainer  } from 'react-hot-loader'
import {eventManager} from "./managers/EventManager.js"
import {backendManager} from "./managers/BackendManager.js"
import RegisterForm from "./components/RegisterForm.js"
//import App from "./components/App"
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () =>  { return document.getElementById("root") };

const render = () => {
    ReactDOM.render(
        <AppContainer>
            <RegisterForm/>
        </AppContainer>,
        document.getElementById('root')
    );
}

render();

eventManager.submitInfo$.subscribe((data) => {
    backendManager.postNewPlayer(data).subscribe(
        x => { 
            console.log(x);
            eventManager.generateQR$.next(x) 
        },
        error => { 
            console.log(error);
        },
        () => {
            console.log('Registered'); 
        });
});

eventManager.generateQR$.subscribe((data) => {
    ReactDOM.unmountComponentAtNode(document.getElementById('root'));
    ReactDOM.render(
        <AppContainer>
            <RegisterForm processing={false} qrtext={data.code} />
        </AppContainer>, document.getElementById("root"));
});

// Hot Module Replacement API
if (module.hot) {
    module.hot.accept("./components/RegisterForm.js", () => {
        console.log("HOT LOAD!");
        render();
    });
}
